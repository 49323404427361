*:focus {
  outline: none;
}
.btn.focus, .btn:focus {
  box-shadow: none!important;
}
body {
  overflow-x: hidden!important;
}
html {overflow-x:hidden;}

@media screen and (min-width: 1440px) {
  /* body {
    border: 1px solid green
  }
  .signupWrapper {
    width: 916px;
  } */
}

@media only screen and (max-width: 1439px) and (min-width: 1024px) { 
  /* body {
    border: 1px solid red;
  }
  .signupWrapper {
    width: 916px;
  } */
  .background-circle-1 {
    right: 210px;
  }
  .background-circle-5 {
    left: 205px;
  }
  .background-circle-2 {
    top: 820px;
    left: -105px;
  }
  .background-circle-3 {
    top: 1020px;
    left: 80px;
  }
  .background-circle-4 {
    top: 1280px;
    left: 850px;
  }
  .background-circle-6 {
    top: 1178px;
    left: 685px;
  }
}

@media (min-width: 576px){
  .modalWrapper  .modal-dialog {
    max-width: 335px;
  }

}

/* @media only screen and (max-width: 1023px) and (min-width: 375px) { */
@media only screen and (max-width: 1023px) {
  .formBottomImageBoxInner {
    margin-bottom: 20px;
}
  .signupWrapper, .faqWrapper, .feedbackWrapper {
    width: 360px!important;
  }
  .desktop-view {
    display: none!important;
  }
  .mobile-view {
    display: block;
  }

  .desktop-left-section,
  #alliumText,
  .stepBoxOne,
  .stepBoxTwo,
  .desktop-middle-section,
  .desktop-right-section,
  .bottom-left-section,
  .formBotoomImageBox,
  .invite-area {
    width: 100%!important;
  }
  .desktop-left-section {
    margin-top: 0px;
  }
  .stepBoxTwo {
    margin-left: 0px;
    margin-top: 40px;
  }
  .desktop-middle-section .count, .signupWrapper .count {
    right: 80px;
  }
  .desktop-middle-section {
    margin-left: 0px;
    margin-top: 40px;
  }
  .desktop-middle-section img {
    width: 179px;
  }
  .desktop-right-section {
    margin-top: 40px;
  }
  .formBotoomImageBoxInner {
    width: 30%;
    float: left;
    margin-bottom: 30px;
    margin-right: 5%;
  }
  .formBotoomImageBoxInner:last-child {
    margin-right: 0px;
  }
  .formBotoomImageBoxInner img {
    width: 100%;
  }
  .footer {
    margin-top: 30px;
    float: right;
    clear: both;
    width: 100%;
  }
  .stepBoxOne img, .stepBoxTwo img {
    width: 162px;
  }
  .background-circle-1 {
    top: 50px;
    right: -185px;
    width: 227px;
    height: 227px;
  }
  .background-circle-5 {
    top: 280px;
    left: 270px;
    width: 104px;
    height: 104px;
  }
  .background-circle-2 {
    top: 1065px;
    left: 115px;
    width: 126px;
    height: 126px;
  }
  .background-circle-3 {
    top: 1185px;
    left: -100px;
    width: 198px;
    height: 198px;
    transform: scaleX(-1);
  }
  .background-circle-4 {
    top: 1975px;
    left: 255px;
    width: 198px;
    height: 198px;
    border: 12px solid #dcaaeb;
  }
  .background-circle-6 {
    top: 2765px;
    left: 30px;
    width: 126px;
    height: 126px;
    transform: scaleX(-1);
    border: 12px solid #d6ef93;
  }
  .for-mobile {
    right: 192px;
  }

  /* landing page */
  h3.topHeadingText {
    text-align: left!important;
  }
  .angle-area {
    float: none!important;
    margin: 0 auto;
  }
  .invite-area {
    margin-top: 20px;
  }
  .inviteBtnBox {
    width: 185px;
    float: none!important;
    margin: 0 auto;
    display: block!important;
  }
  .inviteBtnBox button {
    display: block;
    width: 100%;
    margin-left: 0px!important;
    margin-bottom: 30px;
  }
  
  /* end landing page */
}

@media (max-width: 576px){
  label.questionlbl {
    font-size: 16px !important;
  }  
  p.answerpara {
    font-size: 14px!important;
  }
  .formBottomImageBoxInner img {
    width: 105px;
    height: 110px;
}
.formBottomImageBoxInner {
  margin-bottom: 0px;
}
    .formBottomImageBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
    }
}

@media (max-width: 320px){
.customerPageWrapper .angle-area {
  margin-left: 3px;
}
}