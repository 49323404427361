#crl
{
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.desktop-view {
  display: block;
}
.mobile-view {
  display: none;
}
.signupWrapper .logoText{
  font-family: 'Comfortaa', cursive;
  font-size: 48px;
  color: #6c0a92;
  font-weight: 600;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 50px;
  margin-left: 5px;
}
.signupWrapper {
  margin: 0 auto;
  width: 915px;
  position: relative;
}

.signupWrapper .logoBox {
  clear: both;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0px;
}
.signupWrapper .logoBox a {
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

.contentWrapper {
  clear: both;
  display: block;
  /* justify-content: flex-end; */
}
#alliumText {
  margin-bottom: 30px;
  width: 310px;
  float: right;
}
#alliumText h3{
  font-family: 'Comfortaa', cursive;
  font-size: 26px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 30px;
  margin-bottom: 8px; 
}
#alliumText ul {
  padding-left: 0px;
  margin: 0px;
  list-style-type: none;
}
#alliumText ul li{
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 22px;  
  position: relative;
  padding-left: 15px;
}
#alliumText ul li b {
  font-size: 25px;
  position: absolute;
  top: -6px;
  left: 0px;
}


.desktop-left-section {
  float: left;
  width: 340px;
  margin-top: -92px;
}
.stepBoxOne {
  width: 145px;
  float: left;
}
.stepBoxTwo {
  width: 145px;
  float: left;
  margin-left: 40px;
}
.steps-section {
  float: left;
  width: 100%;
  margin-top: 60px;
}
.stepContentInner {
  margin-bottom: 24px;
}
.stepBoxOne h3, .stepBoxTwo h3,
.stepContentInner h3, .desktop-middle-section h3
{
  font-family: Roboto;
  font-size: 22px;
  color: #300345;
  font-weight: 500;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 25px;
}
.stepBoxOne p, .stepBoxTwo p,
.stepContentInner p, .desktop-middle-section p {
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 22px;
}
.desktop-middle-section {
  float: left;
  margin-left: 40px;
  margin-top: -135px;
  position: relative;
}
.desktop-middle-section img {
  width: 179px;
  border-bottom:1px solid #e7e6e7; 
}



.desktop-right-section {
  float: right;
  width: 310px;
}
.desktop-right-section .formBoxWrapper {
  border-radius: 8px;
  background-color: #e3e3e3;
  background-size: cover;
  padding: 25px 20px;
}
.desktop-right-section .formBoxWrapper h3{
  font-family: Roboto;
  font-size: 18px;
  color: #300345;
  font-weight: 300;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 22px;
  margin-bottom: 15px;
}
.desktop-right-section .formBoxWrapper label.form-label{
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  font-weight: 500;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 20px
}
.desktop-right-section .formBoxWrapper .form-group {
  margin-bottom: 24px;
}
.desktop-right-section .formBoxWrapper input.form-control,
.desktop-right-section .formBoxWrapper select.form-control,
.desktop-right-section .formBoxWrapper input.mobileTextBox.form-control {
  padding: 0px 64px 0px 12px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Roboto;
  font-size: 16px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33);
}
.desktop-right-section .formBoxWrapper input.mobileTextBox.form-control { 
  width: 150px;
  padding-right: 35px;
}
.desktop-middle-section .count,
.signupWrapper .count {
  font-family: Montserrat;
  font-size: 36px;
  color: #212121;
  font-weight: 900;
  text-decoration: none solid rgb(33, 33, 33);
  line-height: 36px;
  text-align: center;
  width: 52px;
  height: 52px;
  background-color: #ffc04d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  bottom: -8px;
}
.mobile-exist .formError {
  top: -30px!important;
}


.bottom-left-section {
  float: left;
  width: 515px;
  margin-top: 36px;
}
.bottom-left-section h3 {
  font-family: Roboto;
  font-size: 22px;
  color: #300345;
  font-weight: 500;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 25px;
  margin-bottom: 25px;
}
.bottom-left-section ul {
  margin: 0px;
  padding: 0px;
  padding-left: 18px;
  margin-bottom: 35px;
}
.bottom-left-section p {
  margin-bottom: 20px;
}
.bottom-left-section ul li,
.bottom-left-section p {
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 22px;
  /* padding-bottom: 5px; */
}


.bottom-right-section {
  float: right;
  width: 207px;
  margin-top: 36px;
}
.bottom-right-section .formBottomImageBoxInner img {
  width: 100%;
  margin-bottom: 35px;
}

.no-margin {
  margin: 0px!important;
}

.background-circle {
  position: absolute;
  z-index: -1;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}
.background-circle-1 {
  top: -120px;
  right: 130px;
  width: 360px;
  height: 360px;
  border: 12px solid #dcaaeb;
}
.background-circle-5 {
  top: 182px;
  left: 320px;
  width: 136px;
  height: 136px;
  transform: scaleX(-1);
  border: 12px solid #d6ef93;
}
.background-circle-2 {
  top: 776px;
  left: -195px;
  width: 198px;
  height: 198px;
  border: 12px solid #dcaaeb;
}
.background-circle-3 {
  top: 977px;
  left: 40px;
  width: 126px;
  height: 126px;
  transform: scaleX(-1);
  border: 12px solid #d6ef93;
}
.background-circle-4 {
  top: 1280px;
  left: 930px;
  width: 198px;
  height: 198px;
  border: 12px solid #d6ef93;
  transform: scaleX(-1);
}
.background-circle-6 {
  top: 1178px;
  left: 750px;
  width: 126px;
  height: 126px;
  transform: scaleX(-1);
  border: 12px solid #dcaaeb;
}

.signupWrapper .stepBoxImages {
  display: flex;
  justify-content: space-around;
}
.signupWrapper .stepBoxOne{
  position: relative;
}
.dispResShow{
  display: none;
}
/* .signupWrapper .stepBoxOne img {
  width: 100%;
}
.signupWrapper .stepBoxTwo img{
  width: 100%;
} */
.signupWrapper .stepBoxTwo{
  position: relative;
}


.signupWrapper .previewHangerBtn{
  border-radius: 6px;
  background-color: #6c0a92;
  background-size: cover;
  font-family: Roboto;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 20px;
  padding: 15px;
  border: 0px;
  width: 100%;
}
.previewHangerBtn:hover, .previewHangerBtn:focus, .previewHangerBtn:active{
  color: #fff;
  background-color: #6c0a929e;
  border-color: #6c0a929e;
}
.signupWrapper .previewHangerBox{
  text-align: center;
  margin-top: 20px;
}
.signupWrapper .stepContent {
  margin-top: 50px;
}
.signupWrapper .stepContent h3{
  font-family: Roboto;
  font-size: 22px;
  color: #212121;
  font-weight: 500;
  text-decoration: none solid rgb(33, 33, 33);
  line-height: 25px;
}
.signupWrapper .stepContent span{
  font-family: Roboto;
  font-size: 16px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33);
  line-height: 22px; 
}
.signupWrapper .stepContent .stepContentInner {
  margin-bottom: 25px;
}

.signupWrapper .alliumInfo_wrapper {
  margin-top: 36px;
}

.footer {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.footer span{
  font-family: Roboto;
  font-size: 12px;
  color: #737373;
  text-decoration: none solid rgb(115, 115, 115);
  line-height: 15px;
}
.colRightPad {
  padding-right: 7.5px;
}
.colLeftPad{
  padding-Left: 7.5px;
}
.signupWrapper .marginTopMinus{
 margin-top:-150px;
}

/*Modal Css Start*/
.modalWrapper .modal-header{
border: 0px;
}
.modalWrapper  .modal-title.h4 {
  display: block;
  float: left;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 500;
  text-decoration: none solid rgb(74, 74, 74);
  line-height: 20px;
  text-align: center
}

.modalWrapper .headerTextTip{
  font-family: Roboto;
  font-size: 13px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33);
  line-height: 18px;
}
.verifyEditBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
.mobileTextArea,
.mobileTextBox {
  position: relative;
  width: 100%;
}
.mobileTextBox label {
  display: -webkit-box;
  margin-bottom: 0px;
  font-family: Roboto;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 700;
  text-decoration: none solid rgb(74, 74, 74);
  line-height: 20px;
}
.mobileTextBox span{
  font-family: Roboto;
  font-size: 16px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33);
  line-height: 22px;
}
.verifyEditInnerBtnBox {
  position: absolute;
  right: 0px;
  margin-top: -35px;
}
.mobileIconBox i {
  font-size: 40px;
  color: #000;
}
.mobileIconBox {
margin-right: 10px;
}
.btnFootWrapper {
  text-align: right;
  margin-bottom: 30px;
}
.btnFootWrapper button{
  background-color: #6c0a92;
  font-family: Roboto;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 18px;
  border: 0px;
  padding: 15px;
  cursor: pointer;
}
.errorFootWrapper span {
  width: 174px;
  height: 16px;
  font-family: Roboto;
  font-size: 13px;
  color: #737373;
  text-decoration: none solid rgb(115, 115, 115);
  line-height: 16px;
  
}
.errorFootWrapper {
  text-align: right;
  margin-bottom: 12px;
}
button.editBtn {
  background: transparent;
  border: 0px;
  color: #6c0a92;
  font-weight: 600;
  font-family: Roboto;
  font-size: 16px;
  color: #6c0a92;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 22px;
}
button.resendBtn {
  font-weight: 400;
  font-family: Roboto;
  font-size: 16px;
  color: #6c0a92;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 22px;
  background: no-repeat;
  padding: 0px;
  margin-bottom: 10px;
}
.modalWrapper .modal-header .close {
  opacity: 1;
  z-index: 111;
  cursor: pointer;
}
.modal-header .close:focus {
 border: 0px!important;
 outline: none!important;
}
.modalWrapper .modal-header .close span {
  font-size: 30px;
  color: #764b8e;
  background-size: cover;
  transform: scaleX(-1) rotate(-48deg);
}
.modalWrapper .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otpWrapper{
  margin-top: 30px;
  margin-bottom: 30px;
}
.verifyMoStep2  .form-group label{
  font-family: Roboto;
  font-size: 16px;
  color: #260339;
  font-weight: 500;
  text-decoration: none solid rgb(38, 3, 57);
  line-height: 20px;
}
.verifyMoStep2  input.form-control{
  padding: 0px 32px 0px 12px;
  border: 1px solid #63cef8;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Roboto;
  font-size: 16px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33);
}
.verifyMoStep2 .veryFyEditInnerBtnBox {
  margin-top: 0px;
  text-align: right;
  margin-bottom: 25px;
}
.verifyMoStep2 .veryFyEditInnerBtnBox button.editBtn {
  font-weight: 400;
}
.verifyMoStep2 .btnFootWrapper {
  margin-bottom: 15px;
}
/*Modal Css End*/

.success-box {
  border: 1px solid #40c353!important;
}
.form-group {
  position: relative;
}
.fa-check {
  color: #40c353;
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.formError{
  color:#c34042 !important;
  font-family: Roboto;
  font-size: 12px;
  position: relative;
  top: -5px;
}
.edit-mobile-formerror {
  font-size: 12px!important; 
  position: absolute;
  top: 56px;
}
.error-box {
  border: 1px solid #c34042!important;
}
.fa-exclamation-triangle {
  color: #c34042;
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 34px;
}
.modal-body .fa-exclamation-triangle {
  bottom: 0px;
  top: 30px;
}
.modal-body .otpWrapper .fa-exclamation-triangle {
  top: 42px;
}
.for-mobile {
  right: 145px;
}
.for-edit-mobile {
  right: 25px;
}
.success-box.form-control , .capitalword {
  text-transform: capitalize;
}
span.formError.resendotpText {
  color: #4ab905 !important;
  margin-top: 10px;
  display: block;
}
.leftfooter a {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;

}
.leftfooter {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.dividerLine {
  margin: 0px 7px;
  font-size: 12px;
  font-weight: 600;
}