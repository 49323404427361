.faqMainWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background-image: linear-gradient(60deg, #bb58d8 0%, #ffffff 100% );
  background-position: center center;
  background-size: cover;
  z-index: -1;
}
.faqWrapper {
  margin: 0 auto;
  width: 915px;
  position: relative;
}
.faqWrapper .logoText{
  font-family: 'Comfortaa', cursive;
  font-size: 48px;
  color: #6c0a92;
  font-weight: 600;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 50px;
  margin-left: 5px;
}
.faqWrapper .logoBox {
  clear: both;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0px;
}
.faqWrapper .logoBox a {
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}
p.answerpara {
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  line-height: 22px;
  padding-left: 12px;
}
label.questionlbl {
  font-family: 'Comfortaa', cursive;
  font-size: 21px;
  color: #300345;
  line-height: 30px;
  margin-bottom: 8px;
}
.faqtitle {
  text-align: center;
  margin-bottom: 35px;
}
.faqtitle h1{
  font-family: 'Comfortaa', cursive;
  font-size: 28px;
  color: #300345;
}
.faqsubcontent {
  margin-bottom: 30px;
}
.faqContent {
  padding-bottom: 30px;
}

p.answerpara a {
  color: #300345;
  text-decoration: underline solid rgb(108, 10, 146);
}