.feedbackMainWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background-image: linear-gradient(60deg, #bb58d8 0%, #ffffff 100% );
  background-position: center center;
  background-size: cover;
  z-index: -1;
}
.feedbackWrapper {
  margin: 0 auto;
  width: 915px;
  position: relative;
}
.feedbackWrapper .logoText{
  font-family: 'Comfortaa', cursive;
  font-size: 48px;
  color: #6c0a92;
  font-weight: 600;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 50px;
  margin-left: 5px;
}
.feedbackWrapper .logoBox {
  clear: both;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0px;
}
.feedbackWrapper .logoBox a {
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

.feedbacktitle {
  text-align: center;
  margin-bottom: 35px;
}
.feedbacktitle h1{
  font-family: 'Comfortaa', cursive;
  font-size: 28px;
  color: #300345;
}

.feedbackContent {
  padding-bottom: 30px;
}
.feedbackContent iframe {
  width: 100% !important;
  /* height: 600px!important; */
}
.feedbackContent iframe html{
  overflow:  hidden !important;
}