.landingWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background-image: linear-gradient(60deg, #bb58d8 0%, #ffffff 100% );
  background-position: center center;
  background-size: cover;
  z-index: -1;
}
.signupWrapper {
  margin: 0 auto;
  width: 915px;
  position: relative;
}
.signupWrapper .logoText{
  font-family: 'Comfortaa', cursive;
  font-size: 48px;
  color: #6c0a92;
  font-weight: 600;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 50px;
  margin-left: 5px;
}
.signupWrapper .logoBox {
  clear: both;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0px;
}
.signupWrapper .logoBox a {
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

/*Hanger Box Image Start*/
/* .hangerBoxImageInner{
  position: relative;
  height: 921px;
} */
.hangerBoxImageInner {
  position: relative;
  height: 100%;
  
}
.doorHangerLawn {
  background-image: url("../../assets/images/doorHangerLawn.png");
  background-size: cover;
}
.doorHangerMaid {
  background-image: url("../../assets/images/doorHangerMaid.png");
  background-size: cover;
}
.doorHangerPest {
  background-image: url("../../assets/images/doorHangerPest.png");
  background-size: cover;
}
.doorHangerPool {
  background-image: url("../../assets/images/doorHangerPool.png");
  background-size: cover;
}
span.titleUser {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  font-family: Roboto;
}

.userBeforeAfterImage {
  text-align: center;
  margin-bottom: 25px;
}
.userBeforeAfterImage span {
  color: #000;
  font-size: 13px;
  font-weight: 600;
  font-family: Montserrat;
  width: 100%;
  margin-bottom: 10px;
}
.userBeforeAfterImage img{
  width: 100%;
  height: 120px;
  object-fit: contain;
}
/* .hangerContentBox {
  padding: 15px;
  padding-top: 330px!important;
} */
.hangerContentBox {
  padding: 15px;
  /* padding-top: 405px!important; */
}
/* .doorHangerPool .hangerContentBox {
  padding-top: 300px!important;
} */
.hangerContentInnerBox h1 {
  font-family: Comfortaa;
  font-size: 26px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  word-break: break-word;
  margin-bottom: 30px;
}
.profil-image {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.profil-image img {
  width: 60px!important;
  height: 60px;
  border-radius: 50%;
}
.hangerContentInnerBox h3 {
  font-family: Roboto;
  font-size: 18px;
  color: #000;
  margin-bottom: 5px;
  font-weight: 500;
}
.hangerContentInnerBox span {
  font-family: Roboto;
  color: #7b7b7b;
  font-size: 13px;
  display: block;
  line-height: 1.2;
}
.hangerContentInnerBox h4 {
  font-size: 14px;
  font-family: Roboto;
  color: #000;
  margin-top: 20px;
  margin-bottom: 5px;
}
.hangerContentInnerBox {
  margin-bottom: 22px;
}
.hangerContentFooterBox {
  text-align: center;
}
.hangerContentFooterBox h3 {
  font-size: 18px;
  color: #000;
  margin-bottom: 0px;
  font-family: Roboto;
  font-weight: 500;
  word-break: break-word;
}
.hangerContentFooterBox span {
  color: #7b7b7b;
  font-family: Roboto;
  font-size: 11px;
  margin-top: 20px;
  width: 100%;
  display: block;
  text-align: center;
}

.doorHangerPool .hangerContentInnerBox h1 {
  color: #00eef9;
  font-weight: 600;
}
/*Hanger Box Image End*/


h3.topHeadingText{
  font-family: Comfortaa;
  font-size: 26px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 30px;
  text-align: right;
  margin-bottom: 50px;
  margin-top: 20px;
}
h4.interestText{
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 22px; 
  text-align: left;
}
.inviteBtnBox{
  margin-top: 32px;
  justify-content: space-between;
  display: flex;
  float: right;
}
.btnFill{
  border-radius: 6px;
  background-color: #6c0a92;
  font-family: Roboto;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 20px;
  padding:14px;
  border: 0px;
}
.btnOutline{
  border: 3px solid #6c0a92;
  border-radius: 6px;
  font-family: Roboto;
  font-size: 20px;
  color: #6c0a92;
  font-weight: 700;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 20px;
  padding:14px;
}
.inviteBtnBox button{
 width: 185px;
 margin-left: 25px;
}
.btn-primary:hover, .btn-outline-primary:hover, .btn-primary:focus, .btn-outline-primary:focus, .btn-primary:active, .btn-outline-primary:active{
  color: #fff;
  background-color: #6c0a929e;
  border-color: #6c0a929e;
}

.angle-body {
  float: left;
  width: 100%;
  margin-bottom: 50px;
}
/* .angle-area {
  float: left;
  width: 280px;
} */
.angle-area {
  float: left;
  width: 315px;
}
.invite-area {
  float: right;
  width: 400px;
}

.loadingWrapper{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center;
  align-content: center;
  align-items: center;
  opacity: 0.8;
  background-image: linear-gradient(60deg, #bb58d8 0%, #ffffff 100% );
  background-position: center center;
  background-size: cover;
  color: #000;
  font-size: 28px;
}
.infoMsgWrapper {
  margin-top: 20px;
  clear: both;
  float: left;
  width: 100%;
}
.infoMsgWrapper p {
  margin: 0px;
  color: #269950;
  /* border: 3px solid #269950; */
  padding: 10px;
  border-radius: 6px;
  font-family: Comfortaa;
  font-size: 23px;
  font-weight: 700;
  text-decoration: none solid rgb(108, 10, 146);
  line-height: 20px;
}

.modalWrapper .modal-title.h4 {
  position: absolute;
}
.btnFootWrapper {
  margin-bottom: 10px;
}
.modal-body p {
  font-family: Roboto;
  font-size: 13px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33);
  line-height: 18px;
  margin-bottom: 50px;
}
.btnFootWrapper .signup-close {
  padding: 15px 45px;
}

.share-modal h3 {
  font-family: Roboto;
  font-size: 16px;
  color: #300345;
  font-weight: 500;
  text-decoration: none solid rgb(48, 3, 69);
  line-height: 20px;
}
.share-modal .modal-body p {
  margin-bottom: 20px;
}
.share-modal .mb-3.input-group {
  margin-bottom: 3px!important;
}
.share-modal input.form-control {
  /* padding: 0px 64px 0px 12px; */
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Roboto;
  font-size: 16px;
  color: #212121;
  text-decoration: none solid rgb(33, 33, 33)
}
.share-modal .input-group button {
  font-family: Roboto;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 25px;
  text-align: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #6c0a92;
  background-size: cover;
}
.share-modal ul.social-share {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  width: 80%;
  margin: 0 auto;
}
.share-modal ul.social-share li {
  display: inline-block;
  width: 33.33%;
  text-align: center;
}
.share-modal ul.phone-view li{
  width: 25%;
}

.share-modal ul.social-share li .share-button,
.share-modal ul.social-share li .sms-share a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: #FFF;
}
.share-modal ul.social-share li .sms-share {
  background-color: #28a745;
  padding: 7px 10px;
  position: relative;
  top: 2px;
}
.share-modal ul.social-share li .sms-share a {
  color: #FFF;
}
.share-modal ul.social-share li svg {
  cursor: pointer;
}
.share-modal ul.social-share li i.fa-envelope-o {
  background-color: #4e9231;
}
.share-modal ul.social-share li i.fa-facebook {
  background-color: #1579ea;
}
.share-modal ul.social-share li i.fa-twitter {
  background-color: #00a2ec;
}

.doorHangerLawn .hangerContentInnerBox h1 {
  color: white;
}

@-webkit-keyframes doorhangerswinging{
  20% { transform: rotate(13deg); }
  40% { transform: rotate(-7deg); }
  60% { transform: rotate(4deg); }
  80% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

@keyframes doorhangerswinging{
  20% { transform: rotate(13deg); }
  40% { transform: rotate(-7deg); }
  60% { transform: rotate(4deg); }
  80% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

.doorhanger{
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-animation: doorhangerswinging 4.5s ease-in-out forwards 1;
  animation: doorhangerswinging 4.5s ease-in-out forwards 1;

}



.hangerTitleContentBox .hangerContentInnerBox{
  position: absolute;
  width: 100%;
  bottom: -30px;
  padding: 10px;
}
.hangerTitleContentBox {
  height: 500px;
  position: relative;
}
.nextServiceWrapper{
  margin-top: 50px;
}

.doorHangerPool .hangerTitleContentBox {
  height: 450px;
} 

.doorHangerMaid .hangerTitleContentBox {
  height: 490px;
}
.doorHangerMaid .hangerTitleContentBox .hangerContentInnerBox{
  bottom: -40px;
}

span.dateSpan {
  display: flex;

}
span.atSpan {
  margin: 0px 7px;
}

span.calendarIconInner {
  color: #000;
  font-size: 20px;
  margin-right: 10px;
}
span.calendarIcon {
  display: flex;
  align-items: center;
}
.shareLinkWrapper {
  clear: both;
  margin-top: 25px;
  width: 100%;
  float: left;
}
span.shareLink {
  font-size: 20px;
  color: #000;
}
span.shareLink a {
  color: #0361c5;
  text-decoration: underline;
  word-break: break-all;
}
.customerPageWrapper.angle-body {
  display: flex;
}
.customerPageWrapper .angle-area {
  justify-content: center;
  margin: 0 auto;
}